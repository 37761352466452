import React, {Component, useState,useContext, useEffect,useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { YearCalendar } from '@mui/x-date-pickers';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import CircularProgress from "@mui/material/CircularProgress";
import Home from './Home.js';
import ApiImportFileXlsx from '../services/api/ApiImportFileXlsx';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {AuthContext} from '../App.js';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import RefreshIcon from "@mui/icons-material/Refresh";
import ApiShipmentImportFile from "../services/api/ApiShipmentImportFile";
import ApiAllocation from "../services/api/ApiAllocation";
import FileDownload from "js-file-download";
import ApiOrderManagement from "../services/api/ApiOrderManagement";
import Loading from "./Loading";

export default function AllocationReport() {
    const {open, setOpen, userNPass,setUserNPass, isLoading, setIsLoading, isProfiles, setIsProfiles, isProgress, setIsProgress} = useContext(AuthContext);
    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [drawer, setDrawer] = useState('none');

    const DrawerBtn = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (drawerOpenBtn === false) {
            setDrawer('block')
        }
        if (drawerOpenBtn === true) {
            setDrawer('none')
        }
    };

    const monthly = [{name:'มกราคม',no:'01'},
        {name:'กุมภาพันธ์',no:'02'},
        {name:'มีนาคม' ,no:'03'},
        {name:'เมษายน',no:'04'},
        {name:'พฤกษภาคม',no:'05'},
        {name:'มิถุนายน',no:'06'},
        {name:'กรกฏาคม',no:'07'},
        {name:'สิงหาคม',no:'08'},
        {name:'กันยายน',no:'09'},
        {name:'ตุลาคม',no:'10'},
        {name:'พฤษจิกายน',no:'11'},
        {name:'ธันวาคม',no:'12'}]

    const [startMonth,setStartMonth] = useState(null);
    const [endMonth,setEndMonth] = useState(null);

    const [yearr,setYearr] = useState(null);

    const [dataExport,setDataExport] = useState({
        bu:null,
        yearDE:null,
        startMonthDE:null,
        endMonthDE:null,
        datetime:null,
        buOptions: [],
        buSiteCode: null,
        buValue: null,
        buInput: '',
    })

    useEffect( () =>{
        async function inti() {
            console.log('useEffect')
            await fetchData().catch((e)=>{
                console.log(e)
                setIsLoading(false)
                setIsProgress(false)
            })
        }
        inti().catch(console.error)
    }, []);

    const fetchData = async () => {
        setIsLoading(true)
        setIsProgress(true)
        let getBu = await ApiAllocation.getBu({   bu: JSON.parse(localStorage.getItem("profile")).bu,});
        if (getBu.status === 200){
            console.log(getBu.data);
            setDataExport(dataExport=>({...dataExport,...{buOptions: getBu.data}}))
        }
        setIsLoading(false)
        setIsProgress(false)
    };

    return(
        <>
            {
                !isProfiles
                    ? <Loading />
                    :
                    <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, overflow:'auto', pb:'30px'}}>
                        <Grid container>
                            <Grid xs={11} item>
                                <Typography variant="h5" sx={{pl: '0px', pb: '15px', fontSize:'22px'}}>
                                    Allocation Report
                                </Typography>
                            </Grid>
                            <Grid container item xs={1} sx={{display: {xs: 'block', sm: 'none'},}} direction="row" alignItems="end">
                                <IconButton aria-label="delete" onClick={DrawerBtn}>
                                    {drawerOpenBtn ? <ExpandMore/> : <ExpandLess/>}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{mb: '10px'}}/>
                        <Grid container spacing={2} sx={{pt: 1, pl: {xs: 0, md: 1}, display: {xs: drawer, sm: 'block'},}}>
                            <Grid container spacing={2} item sx={{mb: '20px'}}>
                                {/*search*/}
                                <Grid xs={12} sm={6} md={6} lg={6} item>
                                    {/*<TextField*/}
                                    {/*    label="BU name"*/}
                                    {/*    value={dataExport.bu}*/}
                                    {/*    fullWidth*/}
                                    {/*    onChange={(e)=>{*/}
                                    {/*        setDataExport(dataExport=>({...dataExport,...{bu: e.target.value}}))*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <Autocomplete
                                        fullWidth
                                        value={dataExport.buValue}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                            if (newValue){
                                                setDataExport(dataExport=>({...dataExport,...{buValue: newValue.label,buSiteCode: newValue.wslSiteCode}}))
                                                // setDataField(dataField=>({...dataField,...{fleetUsage: newValue.label,fleetUsageId: newValue.id}}))
                                            } else {
                                                setDataExport(dataExport=>({...dataExport,...{buValue: null,buSiteCode: null,buInput: ''}}))
                                                // setDataField(dataField=>({...dataField,...{fleetUsage: null,fleetUsageId: null}}))
                                                // setValueDropdown(valueDropdown=>({...valueDropdown, ...{fleetUsage: ''}}))
                                            }
                                        }}
                                        inputValue={dataExport.buInput}
                                        onInputChange={(event, newInputValue) => {
                                            setDataExport(dataExport=>({...dataExport,...{buInput: newInputValue}}))
                                        }}
                                        options={ dataExport.buOptions.length > 0 ?
                                            dataExport.buOptions.map((option) => {
                                                return {
                                                    label: option.nameTH,
                                                    id: option.id,
                                                    // siteCode: option.siteCode,
                                                    wslSiteCode: option.wslSiteCode,
                                                }})
                                            : []
                                        }
                                        renderInput={
                                            (params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{py: '3px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, height: '56px',}}
                                                    required
                                                />
                                        }
                                        ListboxProps={{style:{maxHeight: '300px'}}}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={6} lg={6} item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={'"month" and "year"'}
                                            views={['month', 'year']}
                                            value={yearr}
                                            onChange={(newValue) => {
                                                setDataExport(
                                                    dataExport=>({
                                                        ...dataExport,
                                                        ...{yearDE: newValue.$y,startMonthDE: newValue.$M+1,datetime: newValue}
                                                    }))
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {/*<Grid
                                        container
                                        direction="row"
                                        justifyContent="start"
                                        alignItems="center">
                                        <Typography variant="h2">
                                            ปี :
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <YearCalendar
                                                yearsPerRow={4}
                                                value={yearr}
                                                disableHighlightToday = {true}
                                                sx={{height: '60px',border:'1px lightgray solid'}}
                                                onChange={(newValue) => {
                                                    setDataExport(dataExport=>({...dataExport,...{yearDE: newValue.$y}}))
                                                    // console.log(newValue)
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>*/}
                                </Grid>
                                {/*<Grid xs={12} sm={6} md={6} lg={6} item>
                                    <Autocomplete
                                        fullWidth
                                        variant="outlined"
                                        disablePortal
                                        id="combo-box-demo"
                                        options={monthly}
                                        value={startMonth}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            setStartMonth(newValue)
                                            const start = newValue.no
                                            setDataExport(dataExport =>({
                                                ...dataExport,
                                                ...{startMonthDE:start}
                                            }))
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="ตั้งแต่เดือน"
                                                name="start_month"
                                                onKeyDown={(event) => {
                                                    // Prevent any input
                                                    event.preventDefault();
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={6} lg={6} item>
                                    <Autocomplete
                                        fullWidth
                                        variant="outlined"
                                        disablePortal
                                        id="combo-box-demo"
                                        options={monthly}
                                        getOptionLabel={(option) => option.name}
                                        value={endMonth}
                                        onChange={(event, newValue) => {
                                            setEndMonth(newValue)
                                            const end = newValue.no
                                            setDataExport(dataExport =>({
                                                ...dataExport,
                                                ...{endMonthDE:end}
                                            }))
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="ถึงเดือน"
                                                name="end_month"
                                                onKeyDown={(event) => {
                                                    // Prevent any input
                                                    event.preventDefault();
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>*/}
                                <Grid xs={12} sm={12} md={6} lg={6} item sx={{py: 1}} container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="start"
                                >
                                    <Button
                                        disabled={isProgress}
                                        variant="contained"
                                        disableElevation
                                        onClick={ async ()=>{
                                            setIsProgress(true)
                                            if (!dataExport.datetime){
                                                alert('กรุณาเลือกเดือนและปี')
                                            }
                                            console.log('dataExport')
                                            console.log(dataExport)
                                            console.log(typeof dataExport.datetime)
                                            function formatDateToYYYYMM(date) {
                                                const year = date.getFullYear();
                                                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based

                                                return `${year}-${month}`;
                                            }

// Example usage:
                                            const myDate = new Date(dataExport.datetime); // Replace this with your desired date object
                                            const formattedDate = formatDateToYYYYMM(myDate);
                                            console.log('formattedDate');
                                            console.log(formattedDate);

                                            console.log(dataExport)
                                            console.log({monthly: dataExport.yearDE+ '-'+dataExport.startMonthDE, originSiteCode: dataExport.buSiteCode})
                                            if (window.confirm("ยืนยันหรือไม่!") === true) {
                                                const download = await ApiAllocation.allocationReport({monthly: formattedDate, originSiteCode: dataExport.buSiteCode})
                                                // FileDownload(res.data,dataExport.yearDE+'-'+dataExport.startMonthDE+'.xlxs');
                                                const blob = new Blob([download.data], {type: 'application/octet-stream'});
                                                const objectURL = URL.createObjectURL(blob);
                                                const link = document.createElement('a');
                                                link.href = objectURL;
                                                link.download = 'allocated report.xlsx';
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                URL.revokeObjectURL(objectURL);
                                                console.log(download.data)
                                            }
                                            setIsProgress(false)
                                            }}
                                        sx={{
                                            // fontWeight: 'bold',
                                            backgroundColor: '#6F9EE8',
                                            color: '#ffffff',
                                            // width:'100px',
                                            ':hover': {
                                                backgroundColor: '#4380E1',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Export
                                    </Button>
                                    {/*<Button onClick={async ()=>{*/}
                                    {/*    console.log(dataExport)*/}
                                    {/*    // let resReport = await ApiAllocation.allocationReport2();*/}
                                    {/*    // FileDownload(resReport.data,'AllocationReport.xlxs');*/}
                                    {/*    // console.log(resReport.data)*/}
                                    {/*}}>test</Button>*/}
                                </Grid>
                                <Grid></Grid>
                            </Grid>
                            {/*<Divider sx={{mb: '10px'}}/>*/}
                        </Grid>
                    </Box>
            }
        </>
    )
}