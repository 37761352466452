import {confirmAlert} from "react-confirm-alert";
import {Button, createTheme, Stack, ThemeProvider} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Lottie from 'react-lottie';
import * as truck from '../assets/lottie/truck.json'

export default function SplitTruckCustomDialog(title, master, data, onChange) {
    const options = {
        customUI: view,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (
            <ThemeProvider theme={theme}>
                <Card>
                    <div
                        className='center'
                        style={{
                            position: 'relative',
                            minWidth: '450px',
                            maxWidth: '90vw',
                            padding: '16px 0 16px 0'
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </div>

                        <div>
                            <div style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: '16px',
                                paddingLeft: '16px',
                                paddingRight: '16px'
                            }}
                                 dangerouslySetInnerHTML={{
                                     __html: title || '-'
                                 }}/>
                            <Divider/>
                        </div>

                        <div style={{padding: '16px'}}>
                            <div style={{marginBottom: '16px'}}>
                                ประเถทรถที่ลูกค้าขอ: <span
                                style={{fontWeight: '400', color: '#f48c06'}}>{data.truckType ?? "-"}</span>
                            </div>
                            <DialogTruckList
                                master={master}
                                trickingNo={data.trickingNo}
                            />
                        </div>

                        <Divider/>
                        <Stack direction="row" spacing={2} style={{
                            justifyContent: 'right',
                            paddingTop: '16px',
                            paddingRight: '16px',
                            paddingLeft: '16px'
                        }}>
                            <Button
                                id="dialog-btn-close"
                                variant={"contained"}
                                size={'small'}
                                style={{width: '120px', fontWeight: 'lighter', backgroundColor: '#1976d2'}}
                                onClick={() => {
                                    onClose()
                                    onChange()
                                }}
                            >{`บันทึกข้อมูล`}
                            </Button>
                        </Stack>
                    </div>
                </Card>
            </ThemeProvider>
        )
    }
}

function DialogTruckList(props) {
    const [newTruck, setNewTruck] = useState([])
    const mapTripNo = [
        "A",
        "B",
        "C",
        "D"
    ]

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: truck,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {

    }, [])

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Button
                    variant={"outlined"}
                    size={'small'}
                    disabled={!(newTruck.length < 3)}
                    onClick={() => {
                        let current = newTruck

                        const truck = {
                            tripNo: props.trickingNo + "SPLIT" + mapTripNo[newTruck.length ?? 0],
                            truckType: null
                        }

                        current.push(truck)

                        setNewTruck([...current])
                        console.log(newTruck)
                    }}
                > เพิ่มรถ </Button>

                <span style={{
                    fontSize: '16px',
                    fontWeight: '400'
                }}>{newTruck.length}/3</span>
            </div>

            <Stack direction="column" spacing={2} style={{paddingTop: '15px', minHeight: '200px'}}>
                {
                    newTruck?.map((value, index) =>
                        <div>
                            <span>หมายเลขติดตาม: {value.tripNo} </span>
                            <Stack direction="row" spacing={1} style={{paddingTop: '0px'}}>
                                <Autocomplete
                                    key={value + index}
                                    sx={{
                                        '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                                            zIndex: 1056
                                        }
                                    }}
                                    fullWidth
                                    getOptionLabel={(option) => option}
                                    options={props.master}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size={'small'}
                                            sx={{
                                                '& legend': {display: 'none'},
                                                '& fieldset': {top: 0},
                                                height: '56px',
                                            }}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps
                                            }}
                                        />
                                    )}
                                />

                                <IconButton
                                    sx={{p: '5px', m: '0', color: '#dc2f02', width: '45px', height: '45px'}}
                                    onClick={() => {
                                        let newState = newTruck.filter(f => f.tripNo !== value.tripNo)
                                        setNewTruck([...newState])
                                    }}
                                >
                                    <RemoveCircleIcon/>
                                </IconButton>
                            </Stack>
                        </div>
                    )
                }

                {
                    (newTruck?.length ?? 0) <= 0 ?
                        <div style={{ opacity: 0.5, marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
                            <Lottie
                                options={defaultOptions}
                                height={200}
                                width={250}
                                isClickToPauseDisabled={true}
                            />
                        </div> : null
                }
            </Stack>
        </div>
    )
}

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit',
            fontSize: 16,
        },
    }
}

const theme = createTheme({
    components: {
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    },
    palette: {
        primary: {
            main: '#302E2E',
        },
        secondary: {
            main: '#747474',
        },
        info: {
            main: '#397EF5',
        },
        error: {
            main: '#B64648',
        },
        warning: {
            main: '#B64648',
        },
    },
});