import PropTypes from 'prop-types';
import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import ClipboardCheckIcon from '@heroicons/react/24/solid/ClipboardDocumentCheckIcon';
import ClipboardDocumentListIcon from '@heroicons/react/24/solid/ClipboardDocumentListIcon';
import {Avatar, Card, CardContent, Stack, SvgIcon, Typography} from '@mui/material';
import ClockIcon from "@heroicons/react/24/solid/ClockIcon";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import CircleStackIcon from "@heroicons/react/24/solid/CircleStackIcon";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import StoreIcon from '@mui/icons-material/Store';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import {base64ToString, stringToBase64} from "../../util/Encode";

export const Overview = (props) => {
    const {difference, positive = false, sx, value, type, isAbsolute, filter} = props;

    const mapType = {
        "Waiting": "Waiting",
        "Allocated": "Allocated",
        "Reject": "Reject",
        "Approved": "Approved",
        "Loading": "Loading",
        "Done": "Done",
        "Fail": "Fail",
        "ArrivedDC": "Arrived DC",
        "Dispatch": "Dispatch",
        "DepartedStore": "Departed Store",
        "Delivered": "Delivered",
        "ReturnOrigin": "Return Origin",
        "SendToTMS": "Send to TMS"
    }

    const mapStatus = {
        "Approved": [
            "Approved",
            "Done",
            "Fail",
            "ArrivedDC",
            "Dispatch",
            "Delivered",
            "DepartedStore",
            "ReturnOrigin"
        ],
        "ArrivedDC": [
            "Done",
            "Fail",
            "SendToTMS",
            "ArrivedDC",
            "Dispatch",
            "Delivered",
            "DepartedStore",
            "ReturnOrigin"
        ],
        "Dispatch": [
            "ArrivedDC",
            "Dispatch",
            "Delivered",
            "DepartedStore",
            "ReturnOrigin"
        ],
        "Delivered": [
            "Dispatch",
            "Delivered",
            "DepartedStore",
            "ReturnOrigin"
        ],
        "DepartedStore": [
            "Delivered",
            "DepartedStore",
            "ReturnOrigin"
        ],
        "ReturnOrigin": [
            "DepartedStore",
            "ReturnOrigin"
        ],
    }

    const mapWaitingStatus = {
        "Approved": [
            "Approved"
        ],
        "ArrivedDC": [
            "Done",
            "Fail",
            "SendToTMS"
        ],
        "Dispatch": [
            "ArrivedDC"
        ],
        "Delivered": [
            "Dispatch"
        ],
        "DepartedStore": [
            "Delivered"
        ],
        "ReturnOrigin": [
            "DepartedStore"
        ],
    }

    return (
        <Card sx={sx} variant="outlined">
            <CardContent style={{paddingBottom: '15px'}}>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Grid direction={"column"} container item>
                        <Typography
                            color="text.secondary"
                            variant="overline"
                            sx={{pb: 0, mb: 0, fontSize: '22px', lineHeight: 1.2}}
                        >
                            {
                                getTitle(type)
                            }
                        </Typography>

                        <Typography
                            color="text.secondary"
                            variant="caption"
                            sx={{p: 0, mt: 1, mb: isAbsolute ? 5.5 : 1, fontSize: '12px', fontWeight: 'bold',}}
                        >
                            {
                                getSubTitle(type)
                            }
                        </Typography>

                        <Typography style={{
                            position: isAbsolute ? "absolute" : 'relative',
                            bottom: '10px',
                            marginTop: '10px'
                        }} sx={{'fontSize': '25px'}}>
                            {
                                bottomView(type, value)
                            }
                        </Typography>
                    </Grid>

                    {
                        type === 'Total' && <Avatar
                            sx={{
                                backgroundColor: '#80b918',
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <DonutSmallIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        type === 'Waiting' && <Avatar
                            sx={{
                                backgroundColor: '#f48c06',
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <ClockIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        type === 'Allocated' && <Avatar
                            sx={{
                                backgroundColor: '#ffbd00',
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <ClipboardDocumentListIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        (type === 'Reject') && <Avatar
                            sx={{
                                // 4361ee
                                backgroundColor: "#f48c06",
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <SmsFailedIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        (type === 'Cancel') && <Avatar
                            sx={{
                                // 4361ee
                                backgroundColor: "#dc2f02",
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <CancelIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        type === 'Approved' && <Avatar
                            sx={{
                                // 4361ee
                                backgroundColor: "#4895ef",
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <ClipboardCheckIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        type === 'Loading' && <Avatar
                            sx={{
                                backgroundColor: "#ad05b9",
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                <CircleStackIcon/>
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        type === 'Fail' && <Avatar
                            sx={{
                                backgroundColor: '#dc2f02',
                                height: 45,
                                width: 45,
                                ml: '2px'
                            }}
                        >
                            <SvgIcon>
                                {/*<XMarkIcon/>*/}
                                <PriorityHighIcon />
                            </SvgIcon>
                        </Avatar>
                    }

                    {
                        type === 'Done' ?
                            <Avatar
                                sx={{
                                    backgroundColor: '#2b9348',
                                    height: 45,
                                    width: 45,
                                    ml: '2px'
                                }}
                            >
                                <SvgIcon>
                                    <DoneIcon/>
                                </SvgIcon>
                            </Avatar> : null
                    }

                    {
                        type === "ArrivedDC" ?
                            <Avatar
                                sx={{
                                    backgroundColor: '#55a630',
                                    height: 45,
                                    width: 45,
                                    ml: '2px'
                                }}
                            >
                                <SvgIcon>
                                    <WarehouseIcon/>
                                </SvgIcon>
                            </Avatar> : null
                    }

                    {
                        type === "Dispatch" ?
                            <Avatar
                                sx={{
                                    backgroundColor: '#ffbd00',
                                    height: 45,
                                    width: 45,
                                    ml: '2px'
                                }}
                            >
                                <SvgIcon>
                                    <LocalShippingIcon/>
                                </SvgIcon>
                            </Avatar> : null
                    }

                    {
                        type === "Delivered" ?
                            <Avatar
                                sx={{
                                    backgroundColor: '#bfd200',
                                    height: 45,
                                    width: 45,
                                    ml: '2px'
                                }}
                            >
                                <SvgIcon>
                                    <StoreIcon/>
                                </SvgIcon>
                            </Avatar> : null
                    }

                    {
                        type === "DepartedStore" ?
                            <Avatar
                                sx={{
                                    backgroundColor: '#80b918',
                                    height: 45,
                                    width: 45,
                                    ml: '2px'
                                }}
                            >
                                <SvgIcon>
                                    <LocalShippingIcon/>
                                </SvgIcon>
                            </Avatar> : null
                    }

                    {
                        type === "ReturnOrigin" ?
                            <Avatar
                                sx={{
                                    backgroundColor: '#55a630',
                                    height: 45,
                                    width: 45,
                                    ml: '2px'
                                }}
                                style={{rotate: '90deg'}}
                            >
                                <SvgIcon>
                                    <UTurnLeftIcon/>
                                </SvgIcon>
                            </Avatar> : null
                    }
                </Stack>

                {
                    difference && (
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{mt: 2}}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0.5}
                            >
                                <SvgIcon
                                    color={positive ? 'success' : 'error'}
                                    fontSize="small"
                                >
                                    {positive ? <ArrowUpIcon/> : <ArrowDownIcon/>}
                                </SvgIcon>
                                <Typography
                                    color={positive ? 'success.main' : 'error.main'}
                                    variant="body2"
                                >
                                    {difference}%
                                </Typography>
                            </Stack>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                Since last month
                            </Typography>
                        </Stack>
                    )
                }
            </CardContent>
        </Card>
    );

    function getTitle(type) {
        switch (type) {
            case "ArrivedDC":
                return "Arrived DC";
            case "Done":
                return "Sent to TMS";
            case "Fail":
                return "Fail to TMS";
            case "DepartedStore":
                return "Departed Store";
            case "ReturnOrigin":
                return "Return Origin";

            case "subConfirm":
                return "ซับยืนยัน";
            case "arrivedDC":
                return "Arrived DC";
            case "delivered":
                return "ส่งสาขา";
            case "pickUpOrder":
                return "Dispatch";
            case "departedStore":
                return "Departed Store";
            case "returnOrigin":
                return "กลับคลังต้นทาง";

            default:
                return type;
        }
    }

    function getSubTitle(type) {
        switch (type) {
            case "Waiting":
                return "รอการจัดสรร";
            case "Allocated":
                return "รอ Operation ยืนยัน";
            case "Reject":
                return "Vendor Reject";
            case "Approved":
                return "รอ Vendor ยืนยัน";
            case "Loading":
                return "รอระบบประมวลผล";
            case "Done":
                return "ส่งไป TMS สำเร็จ";
            case "Fail":
                return "ส่งไป TMS ไม่สำเร็จ";
            case "ArrivedDC":
                return "รถถึงคลัง";
            case "Dispatch":
                return "ออกคลัง";
            case "Delivered":
                return "ถึงสาขา";
            case "DepartedStore":
                return "ออกสาขา";
            case "ReturnOrigin":
                return "กลับคลังต้นทาง";
            case "Total":
                return "ทั้งหมด";
            case "Cancel":
                return "ยกเลิก";
            default:
                return "";
        }
    }

    function bottomView(type, value) {
        switch (type) {
            case "subConfirm":
                return (
                    <div style={{fontSize: '19px'}}>
                        <li>รอยืนยัน (
                            <Link href={getUrl(filter, "Approved", "Normal", null, true)}>
                                <span>{value?.notTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Approved", "NearDeal", null, true)}>
                                <span style={{color: "#C4B100"}}>{value?.nearDue ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Approved", "OverDeal", null, true)}>
                                <span style={{color: "red"}}>{value?.overDue ?? '-'}</span>
                            </Link>
                            )
                        </li>
                        <li>ยืนยันแล้ว (
                            <Link href={getUrl(filter, "Approved", null, "OnTime", true)}>
                                <span style={{color: "darkgreen"}}>{value?.onTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Approved", null, "Delay", true)}>
                                <span style={{color: "red"}}>{value?.delay ?? '-'}</span>
                            </Link>
                            )
                        </li>
                    </div>
                );
            case "arrivedDC":
                return (
                    <div style={{fontSize: '19px'}}>
                        <li>รอเข้าคลัง (
                            <Link href={getUrl(filter, "ArrivedDC", "Normal", null, true)}>
                                <span>{value?.notTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "ArrivedDC", "NearDeal", null, true)}>
                                <span style={{color: "#C4B100"}}>{value?.nearDue ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "ArrivedDC", "OverDeal", null, true)}>
                                <span style={{color: "red"}}>{value?.overDue ?? '-'}</span>
                            </Link>
                            )
                        </li>
                        <li>
                            เข้าคลังแล้ว (
                            <Link href={getUrl(filter, "ArrivedDC", null, "OnTime", true)}>
                                <span style={{color: "darkgreen"}}>{value?.onTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "ArrivedDC", null, "Delay", true)}>
                                <span style={{color: "red"}}>{value?.delay ?? '-'}</span>
                            </Link>
                            )
                        </li>
                    </div>
                );
            case "pickUpOrder":
                return (
                    <div style={{fontSize: '19px'}}>
                        <li>รอออกคลัง (
                            <Link href={getUrl(filter, "Dispatch", "Normal", null, true)}>
                                <span>{value?.notTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Dispatch", "NearDeal", null, true)}>
                                <span style={{color: "#C4B100"}}>{value?.nearDue ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Dispatch", "OverDeal", null, true)}>
                                <span style={{color: "red"}}>{value?.overDue ?? '-'}</span>
                            </Link>
                            )
                        </li>
                        <li>
                            ออกคลังแล้ว (
                            <Link href={getUrl(filter, "Dispatch", null, "OnTime", true)}>
                                <span style={{color: "darkgreen"}}>{value?.onTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Dispatch", null, "Delay", true)}>
                                <span style={{color: "red"}}>{value?.delay ?? '-'}</span>
                            </Link>
                            )
                        </li>
                    </div>
                );
            case "delivered":
                return (
                    <div style={{fontSize: '19px'}}>
                        <li>
                            รอส่ง (
                            <Link href={getUrl(filter, "Delivered", "Normal", null, true)}>
                                <span>{value?.notTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Delivered", "NearDeal", null, true)}>
                                <span style={{color: "#C4B100"}}>{value?.nearDue ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Delivered", "OverDeal", null, true)}>
                                <span style={{color: "red"}}>{value?.overDue ?? '-'}</span>
                            </Link>
                            )
                        </li>
                        <li>
                            ส่งแล้ว (
                            <Link href={getUrl(filter, "Delivered", null, "OnTime", true)}>
                                <span style={{color: "darkgreen"}}>{value?.onTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "Delivered", null, "Delay", true)}>
                                <span style={{color: "red"}}>{value?.delay ?? '-'}</span>
                            </Link>
                            )
                        </li>
                    </div>
                );
            case "departedStore":
                return (
                    <div style={{fontSize: '19px'}}>
                        <li>รอออกสาขา
                            (
                            <Link href={getUrl(filter, "DepartedStore", "Normal", null, true)}>
                                <span>{value?.notTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "DepartedStore", "NearDeal", null, true)}>
                                <span style={{color: "#C4B100"}}>{value?.nearDue ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "DepartedStore", "OverDeal", null, true)}>
                                <span style={{color: "red"}}>{value?.overDue ?? '-'}</span>
                            </Link>
                            )
                        </li>
                        <li>
                            ออกสาขาแล้ว (
                            <Link href={getUrl(filter, "DepartedStore", null, "OnTime", true)}>
                                <span style={{color: "darkgreen"}}>{value?.onTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "DepartedStore", null, "Delay", true)}>
                                <span style={{color: "red"}}>{value?.delay ?? '-'}</span>
                            </Link>
                            )
                        </li>
                    </div>
                );
            case "returnOrigin":
                return (
                    <div style={{fontSize: '19px'}}>
                        <li>รอกลับ
                            (
                            <Link href={getUrl(filter, "ReturnOrigin", "Normal", null, true)}>
                                <span>{value?.notTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "ReturnOrigin", "NearDeal", null, true)}>
                                <span style={{color: "#C4B100"}}>{value?.nearDue ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "ReturnOrigin", "OverDeal", null, true)}>
                                <span style={{color: "red"}}>{value?.overDue ?? '-'}</span>
                            </Link>
                            )
                        </li>
                        <li>กลับแล้ว (
                            <Link href={getUrl(filter, "ReturnOrigin", null, "OnTime", true)}>
                                <span style={{color: "darkgreen"}}>{value?.onTime ?? '-'}</span>
                            </Link>/
                            <Link href={getUrl(filter, "ReturnOrigin", null, "Delay", true)}>
                                <span style={{color: "red"}}>{value?.delay ?? '-'}</span>
                            </Link>
                            )
                        </li>
                    </div>
                );
            case "Cancel":
            case "Total":
                return <div>{value ?? "-"}</div>;
            default:
                return <Link href={getUrl(filter, type)}>{value ?? "-"}</Link>
        }
    }

    function getMapStatus(status, isWaiting) {
        let statusMap = isWaiting === true ? mapWaitingStatus[status] : mapStatus[status]
        return statusMap.map(m => mapType[m])
    }

    function getUrl(filter, status, waitingStatus, completeStatus, isMapStatus) {
        let url = "/shipment/orderManagement"

        let filterParse = null
        if (filter) {
            const decodeValue = base64ToString(filter)
            filterParse = JSON.parse(decodeValue)
        }

        let filterValue = filterParse ? {
            ...filterParse,
            status: isMapStatus ? getMapStatus(status, waitingStatus !== null) : [mapType[status]],
            waitingStatus: waitingStatus,
            completeStatus: completeStatus,
            checkStatus: mapType[status]
        } : {
            status: isMapStatus ? getMapStatus(status, waitingStatus !== null) : [mapType[status]],
            waitingStatus: waitingStatus,
            completeStatus: completeStatus,
            checkStatus: mapType[status]
        }

        // Debug for test
        // console.log("===== Debug url filter data => ", filterValue)

        const jsonStr = JSON.stringify(filterValue);
        const base64 = stringToBase64(jsonStr)

        url = (url + "?order_filter=" + encodeURIComponent(base64))

        return url
    }
};

Overview.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
    type: PropTypes.string
};
