import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route, createBrowserRouter, RouterProvider} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import Button from '@mui/material/Button';


const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'Kanit',
            textTransform: 'none',
            fontSize: 16,
        },
    },
});


// createRoot(document.getElementById("root")).render(
//     <RouterProvider router={router} />
// );

function LeagueStandings() {
    return (
        <>
            LeagueStandings
        </>
    )
}

function Team() {
    return (
        <>
            Team
        </>
    )
}

function EditTeam() {
    return (
        <>
            EditTeam
        </>
    )
}

function NewTeamForm() {
    return (
        <>
            NewTeamForm
        </>
    )
}

function Home() {
    return (
        <>
            home
        </>
    )
}

function Teams() {
    return (
        <>
            Teams
        </>
    )
}

function PageLayout() {
    return (
        <>
            PageLayout
        </>
    )
}

function Privacy() {
    return (
        <>
            <Button>aaa</Button>
        </>
    )
}

function Tos() {
    return (
        <>
            Tos
        </>
    )
}

function Contact() {
    return (
        <>
            Contact
        </>
    )
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
