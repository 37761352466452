import axios from "axios";
import { API } from "../../config/api";

export default class ApiShipmentImportFile {
  static async importFile(data) {
    try {
      return await axios.post(
        new API().getCusAPI() + "importShipment/importFile",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (e) {
      return e;
    }
  }

  static async importFileToSynx(request) {
    try {
      return await axios.post(
        new API().getZynx() + "makro-create-order",
          // "http://127.0.0.1:8000/makro-create-order",
        request,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  static async autoApprove(request) {
    try {
      return await axios.post(
          new API().getCusAPI() + "orderManagement/Approve",
          request,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
      );
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  static async search(data) {
    try {
      return await axios.post(
        new API().getCusAPI() + "importShipment/search",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      return e;
    }
  }

  static async downloadLog(data) {
    try {
      return await axios.post(
        new API().getCusAPI() + "importShipment/exportErrorLog",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      );
    } catch (e) {
      return e;
    }
  }

  static async downloadImportFileResult(data) {
    try {
      return await axios.post(
        new API().getCusAPI() + "importShipment/downloadImportResult",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      );
    } catch (e) {
      return e;
    }
  }
}
