import React, {Component, useState,useContext, useEffect,useRef} from 'react';
import ApiLogin from '../../services/api/ApiLogin';


export const getProfiles = async (email, isWebView) => {
    let resLogin = await ApiLogin.profile({email: email})
    if (resLogin.status === 200){
        let userId = resLogin.data.data.userId
        let current = isWebView === "true" ? "vmp" : "cmp"
        let resReportList = await ApiLogin.getReportLists(userId, current)

        if (resReportList.status === 200) {
            resLogin.data.data.reportList = resReportList.data
        }

        localStorage.setItem('profile', JSON.stringify(resLogin.data.data));
        console.log('Get profiles success', resLogin.data)
        return resLogin.data
    } else {
        return  null
    }
}