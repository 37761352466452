import React, {useEffect, useState} from 'react';
import {PowerBIEmbed} from "powerbi-client-react";
import { models, Report, Embed, service, Page } from 'powerbi-client';
import {useParams, useSearchParams} from "react-router-dom";
import ApiReport from "../../services/api/ApiReport";
import Loading from "../Loading";

export default function PowerBI(props) {
    const {id} = useParams();

    const [searchParams] = useSearchParams();
    const isWebView = searchParams.get('webView');
    const reportId = searchParams.get('reportId');
    const groupId = searchParams.get('groupId');

    const vendorCode = JSON.parse(localStorage.getItem("profile")).vendorCode

    const [reportConfig, setReportConfig] = useState(undefined)
    const [filters, setFilters] = useState({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "0_TMS_Orders",
            column: "Subcontract_Code"
        },
        operator: "In",
        values: vendorCode ? [vendorCode] : []
    });

    const reportClass = 'report-container';
    const embedUrl = "https://app.powerbi.com/reportEmbed?reportId=" + reportId + "&groupId=" + groupId


    useEffect(() => {
        // console.log("===== Debug profile get vendor code: ", vendorCode, filters)

       ApiReport.getReport(id, reportId, groupId).then((res) => {
           setReportConfig(res.data)
           // console.log("===== Debug get report success: ", res.data)

           let newFilterRes = {...res.data.filter}
           newFilterRes.values = vendorCode ? [vendorCode] : []

           setFilters(newFilterRes)
       }).catch(err => console.log("===== Debug error: ", err))
    }, [])

    return( reportConfig ?
        <div className={'custom-body'}>
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',
                    embedUrl: embedUrl,
                    tokenType: models.TokenType.Embed,
                    accessToken: reportConfig.token,
                    filters: filters,
                    settings: reportConfig.settings
                }}
                eventHandlers = {
                    new Map([
                        ['loaded', function () {console.log('Report loaded');}],
                        ['rendered', function () {console.log('Report rendered');}],
                        ['error', function (event) {console.log(event.detail);}],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }
                cssClassName = { reportClass }
                getEmbeddedComponent = {(embedObject: Embed) => {
                    // console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                    // setReport(embedObject as Report);
                }}
            />;
        </div> : <Loading isWebView={"true"} />
    )
}