import axios from "axios";
import {API} from "../../config/api";

export default class ApiTMSOrderLog {
    static async search(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'TMSOrderLog/search',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async downloadLog(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'tms/order/log',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                    responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }
}