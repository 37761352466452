import axios from "axios";
import {API} from "../../config/api";

export default class Api {
    static async login(data) {
        try {
            return await axios.post(
                new API().getLoginAPI()+'api/login_check',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }
                }
            );
        } catch (e) {
            return e;
        }
    }
    static async profile(data) {
        try {
            return await axios.post(
                new API().getLoginAPI()+'api/getProfile',
                data,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }}
            );
        } catch (e) {
            return e;
        }
    }

    static async getReportLists(userId, current) {
        try {
            return await axios.post(
                new API().getCusAPI()+'api/power-bi/getreportlists',
                {
                    userId: userId,
                    currentWeb: current
                },
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }}
            );
        } catch (e) {
            return e;
        }
    }

    static async profile2(data) {
        try {
            return await axios.post(
                new API().getLoginAPI()+'api/getProfile',
                data,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }}
            );
        } catch (e) {
            return e;
        }
    }
}