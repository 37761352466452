import { object, string, number, date, InferType, mixed } from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

export default function HookForm() {

    const schema = object().shape({
        branch: string().required(),
        email: string().email().required(),
        image1: mixed().required('Image 1 is required'),
        image2: mixed().required('Image 2 is required')
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        console.log("====== TEST LOG DATA BRANCH => ", data);
        reset();
    };

    return(
        <div>
            การเข้างานวันนี้

            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div>
                    <select {...register("branch")} required>
                        <option value="">กรุณาเลือกสาขา</option>
                        <option value="000001">000001</option>
                        <option value="000002">000002</option>
                        <option value="000003">000003</option>
                        <option value="000004">000005</option>
                    </select>
                </div>

                <div>
                    <input {...register("email")} type="email" name="email" placeholder="Email" required/>
                </div>
                <p>{errors.email?.message}</p>

                <div>
                    <input
                        {...register("image1")}
                        type="file"
                        name="image1"
                        accept="image/png, image/jpeg"
                        required
                    />
                </div>

                <div>
                    <input
                        {...register("image2")}
                        type="file"
                        name="image2"
                        accept="image/png, image/jpeg"
                        required
                    />
                </div>
                <input type="submit" value="Submit"/>
            </form>
        </div>
    )
}