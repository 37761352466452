import PropTypes from 'prop-types';
import {Avatar, Card, CardContent, Grid, Popover, Stack, SvgIcon, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from "react";
import ApiOrderManagement from "../../services/api/ApiOrderManagement";
import {Overview} from "./overview";
import {AuthContext} from "../../App";
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';

export const AllOverview = (props) => {
    const {profiles} = useContext(AuthContext);
    const {
        bu,
        statusNum,
        filter,
        iCountSubConfirmStatus,
        iCountDepartOriginStatus,
        iCountArriveDestinationStatus,
        iCountReturnOriginStatus,
        iCountArrivedDC,
        iCountDepartedStore,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <Grid
                container
                columns={{xs: 15, sm: 15, md: 12, lg: 15}}
                style={{paddingBottom: "15px"}}
            >
                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        positive
                        sx={{height: '100%'}}
                        value={statusNum?.total ?? 0}
                        filter={filter}
                        type={'Total'}
                        isAbsolute={true}
                    />
                </Grid>

                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        positive
                        sx={{height: '100%'}}
                        value={statusNum?.Cancel ?? "0"}
                        filter={filter}
                        type={'Cancel'}
                        isAbsolute={true}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                columns={{xs: 15, sm: 15, md: 12, lg: 15}}
                style={{paddingBottom: "15px"}}
            >
                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        positive
                        sx={{height: '100%'}}
                        value={statusNum?.Waiting ?? 0}
                        filter={filter}
                        type={'Waiting'}
                        isAbsolute={true}
                    />
                </Grid>
                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        positive
                        sx={{height: '100%'}}
                        value={statusNum?.Allocated ?? 0}
                        filter={filter}
                        type={'Allocated'}
                        isAbsolute={true}
                    />
                </Grid>
                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        sx={{height: '100%'}}
                        value={statusNum?.Reject ?? 0}
                        filter={filter}
                        type={'Reject'}
                        isAbsolute={true}
                    />
                </Grid>
                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        sx={{height: '100%'}}
                        value={statusNum?.Approved ?? 0}
                        filter={filter}
                        type={'Approved'}
                        isAbsolute={true}
                    />
                </Grid>

                {
                    bu === 'ALL NOW COMPLEX' && (
                        <>
                            <Grid
                                xs={15}
                                sm={7.5}
                                md={4}
                                lg={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={statusNum?.Loading ?? 0}
                                    filter={filter}
                                    type={'Loading'}
                                    isAbsolute={true}
                                />
                            </Grid>
                        </>
                    )
                }

                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        sx={{height: '100%'}}
                        value={statusNum?.Done ?? 0}
                        filter={filter}
                        type={'Done'}
                        isAbsolute={true}
                    />
                </Grid>
                <Grid
                    xs={15}
                    sm={7.5}
                    md={4}
                    lg={3}
                    style={{
                        position: "relative"
                    }}
                >
                    <Overview
                        sx={{height: '100%'}}
                        value={statusNum?.Fail ?? 0}
                        filter={filter}
                        type={'Fail'}
                        isAbsolute={true}
                    />
                </Grid>
                {
                    checkingMakro(bu) && (
                        <>
                            <Grid
                                xs={15}
                                sm={7.5}
                                md={4}
                                lg={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={statusNum?.ArrivedDC ?? 0}
                                    filter={filter}
                                    type={'ArrivedDC'}
                                    isAbsolute={true}
                                />
                            </Grid>
                            <Grid
                                xs={15}
                                sm={7.5}
                                md={4}
                                lg={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={statusNum?.Dispatch ?? 0}
                                    filter={filter}
                                    type={'Dispatch'}
                                    isAbsolute={true}
                                />
                            </Grid>
                            <Grid
                                xs={15}
                                sm={7.5}
                                md={4}
                                lg={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={statusNum?.Delivered ?? 0}
                                    filter={filter}
                                    type={'Delivered'}
                                    isAbsolute={true}
                                />
                            </Grid>

                            <Grid
                                xs={15}
                                sm={7.5}
                                md={4}
                                lg={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={statusNum?.DepartedStore ?? 0}
                                    filter={filter}
                                    type={'DepartedStore'}
                                    isAbsolute={true}
                                />
                            </Grid>

                            <Grid
                                xs={15}
                                sm={7.5}
                                md={4}
                                lg={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={statusNum?.ReturnOrigin ?? 0}
                                    filter={filter}
                                    type={'ReturnOrigin'}
                                    isAbsolute={true}
                                />
                            </Grid>
                        </>
                    )
                }
            </Grid>

            {
                checkingMakro(bu) && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '5px'}}>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                                sx={{p: 0, m: 0, fontSize: '24px', fontWeight: 'medium'}}
                            >
                                สถานะการจัดส่ง
                            </Typography>

                            <InfoIcon
                                style={{
                                    marginLeft: '5px',
                                    cursor: 'pointer',
                                    opacity: 0.8,
                                    width: '18px'
                                }}
                                onMouseEnter={(event) => {
                                    setAnchorEl(event.currentTarget);
                                }}
                                onMouseLeave={() => {
                                    setAnchorEl(null);
                                }}
                            />
                        </div>

                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                            disableRestoreFocus
                        >
                            <Typography sx={{pl: 1, mt: 1}} style={{fontWeight: 'bold'}}>รอ</Typography>
                            <Typography sx={{pl: 1}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <CircleIcon style={{width: '18px', height: '18px', marginRight: '5px'}}/>
                                    <div>ปกติ</div>
                                </div>
                            </Typography>
                            <Typography sx={{pl: 1, mr: 1}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <CircleIcon style={{
                                        width: '18px',
                                        height: '18px',
                                        marginRight: '5px',
                                        color: 'rgb(196, 177, 0)'
                                    }}/>
                                    <div>ใกล้เลยเวลาที่กำหนด (2 ชั่วโมง)</div>
                                </div>
                            </Typography>
                            <Typography sx={{pl: 1, pb: 1}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <CircleIcon style={{
                                        width: '18px',
                                        height: '18px',
                                        marginRight: '5px',
                                        color: 'red'
                                    }}/>
                                    <div>เลยเวลาที่กำหนด</div>
                                </div>
                            </Typography>
                            <Typography sx={{pl: 1}} style={{fontWeight: 'bold'}}>เสร็จ</Typography>
                            <Typography sx={{pl: 1}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <CircleIcon style={{
                                        width: '18px',
                                        height: '18px',
                                        marginRight: '5px',
                                        color: 'red'
                                    }}/>
                                    <div>ช้ากว่ากำหนด</div>
                                </div>
                            </Typography>
                            <Typography sx={{pl: 1, mb: 1}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <CircleIcon style={{
                                        width: '18px',
                                        height: '18px',
                                        marginRight: '5px',
                                        color: 'darkgreen'
                                    }}/>
                                    <div>ตรงเวลา</div>
                                </div>
                            </Typography>
                        </Popover>

                        <Grid
                            container
                            columns={{xs: 15, sm: 15, md: 15, lg: 12, xl: 15}}
                            spacing={0}
                        >
                            <Grid
                                xs={15}
                                sm={7.5}
                                md={5}
                                lg={3}
                                xl={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={iCountSubConfirmStatus}
                                    filter={filter}
                                    type={'subConfirm'}
                                />
                            </Grid>

                            <Grid
                                xs={15}
                                sm={7.5}
                                md={5}
                                lg={3}
                                xl={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={iCountArrivedDC}
                                    filter={filter}
                                    type={'arrivedDC'}
                                />
                            </Grid>

                            <Grid
                                xs={15}
                                sm={7.5}
                                md={5}
                                lg={3}
                                xl={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={iCountDepartOriginStatus}
                                    filter={filter}
                                    type={'pickUpOrder'}
                                />
                            </Grid>
                            <Grid
                                xs={15}
                                sm={7.5}
                                md={5}
                                lg={3}
                                xl={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={iCountArriveDestinationStatus}
                                    filter={filter}
                                    type={'delivered'}
                                />
                            </Grid>

                            <Grid
                                xs={15}
                                sm={7.5}
                                md={5}
                                lg={3}
                                xl={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={iCountDepartedStore}
                                    filter={filter}
                                    type={'departedStore'}
                                />
                            </Grid>

                            <Grid
                                xs={15}
                                sm={7.5}
                                md={5}
                                lg={3}
                                xl={3}
                                style={{
                                    position: "relative"
                                }}
                            >
                                <Overview
                                    sx={{height: '100%'}}
                                    value={iCountReturnOriginStatus}
                                    filter={filter}
                                    type={'returnOrigin'}
                                />
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </>
    );

    function checkingMakro(customerName) {
        return (
            customerName === 'Makro' ||
            customerName === "Makro Fresh - Wangnoi" ||
            customerName === "Makro Fresh - Mahachai" ||
            customerName === "Makro Frozen - Wangnoi" ||
            customerName === "Makro Frozen - Mahachai" ||
            customerName === "Makro Transfer"
        )
    }
};

AllOverview.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
    type: PropTypes.string
};
