import React, {Component, useState, useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import {Divider, Grid, IconButton, Stack} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import {subDays, subHours} from "date-fns";
import Container from "@mui/material/Container";
import ApiOrderManagement from "../services/api/ApiOrderManagement";
import {AllOverview} from "../sections/overview/all-overview";
import {Overview} from "../sections/overview/overview";

const now = new Date();

export default function Home() {
    const [trigger, setTrigger] = useState('N');
    const [statusNum, setStatusNum] = useState({
        'Waiting': 0,
        'Allocated': 0,
        'Approved': 0,
        'Done': 0,
        'Fail': 0
    });

    useEffect( () =>{
        async function inti() {
            setTrigger('N')
            let res = await ApiOrderManagement.getReport();
            setStatusNum(res.data);
            setTrigger('Y')
        }
        inti().catch(console.error)
    }, []);

    return(
                    <Grid
                        container
                        // spacing={3}
                        sx={{"mb":'20px'}}
                    >
                        <AllOverview init={'N'} trigger={trigger}/>
                        {/*<Grid*/}
                        {/*    xs={12}*/}
                        {/*    lg={8}*/}
                        {/*    columns={15}*/}
                        {/*>*/}
                        {/*    <OverviewSales*/}
                        {/*        chartSeries={[*/}
                        {/*            {*/}
                        {/*                name: 'This year',*/}
                        {/*                data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20]*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                name: 'Last year',*/}
                        {/*                data: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13]*/}
                        {/*            }*/}
                        {/*        ]}*/}
                        {/*        sx={{ height: '100%' }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid*/}
                        {/*    xs={12}*/}
                        {/*    md={6}*/}
                        {/*    lg={4}*/}
                        {/*>*/}
                        {/*    <OverviewTraffic*/}
                        {/*        chartSeries={[63, 15, 22]}*/}
                        {/*        labels={['Desktop', 'Tablet', 'Phone']}*/}
                        {/*        sx={{ height: '100%' }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid*/}
                        {/*    xs={12}*/}
                        {/*    md={6}*/}
                        {/*    lg={4}*/}
                        {/*>*/}
                        {/*    <OverviewLatestProducts*/}
                        {/*        products={[*/}
                        {/*            {*/}
                        {/*                id: '5ece2c077e39da27658aa8a9',*/}
                        {/*                image: '/assets/products/product-1.png',*/}
                        {/*                name: 'Healthcare Erbology',*/}
                        {/*                updatedAt: subHours(now, 6).getTime()*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: '5ece2c0d16f70bff2cf86cd8',*/}
                        {/*                image: '/assets/products/product-2.png',*/}
                        {/*                name: 'Makeup Lancome Rouge',*/}
                        {/*                updatedAt: subDays(subHours(now, 8), 2).getTime()*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: 'b393ce1b09c1254c3a92c827',*/}
                        {/*                image: '/assets/products/product-5.png',*/}
                        {/*                name: 'Skincare Soja CO',*/}
                        {/*                updatedAt: subDays(subHours(now, 1), 1).getTime()*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: 'a6ede15670da63f49f752c89',*/}
                        {/*                image: '/assets/products/product-6.png',*/}
                        {/*                name: 'Makeup Lipstick',*/}
                        {/*                updatedAt: subDays(subHours(now, 3), 3).getTime()*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: 'bcad5524fe3a2f8f8620ceda',*/}
                        {/*                image: '/assets/products/product-7.png',*/}
                        {/*                name: 'Healthcare Ritual',*/}
                        {/*                updatedAt: subDays(subHours(now, 5), 6).getTime()*/}
                        {/*            }*/}
                        {/*        ]}*/}
                        {/*        sx={{ height: '100%' }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid*/}
                        {/*    xs={12}*/}
                        {/*    md={12}*/}
                        {/*    lg={8}*/}
                        {/*>*/}
                        {/*    <OverviewLatestOrders*/}
                        {/*        orders={[*/}
                        {/*            {*/}
                        {/*                id: 'f69f88012978187a6c12897f',*/}
                        {/*                ref: 'DEV1049',*/}
                        {/*                amount: 30.5,*/}
                        {/*                customer: {*/}
                        {/*                    name: 'Ekaterina Tankova'*/}
                        {/*                },*/}
                        {/*                createdAt: 1555016400000,*/}
                        {/*                status: 'pending'*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: '9eaa1c7dd4433f413c308ce2',*/}
                        {/*                ref: 'DEV1048',*/}
                        {/*                amount: 25.1,*/}
                        {/*                customer: {*/}
                        {/*                    name: 'Cao Yu'*/}
                        {/*                },*/}
                        {/*                createdAt: 1555016400000,*/}
                        {/*                status: 'delivered'*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: '01a5230c811bd04996ce7c13',*/}
                        {/*                ref: 'DEV1047',*/}
                        {/*                amount: 10.99,*/}
                        {/*                customer: {*/}
                        {/*                    name: 'Alexa Richardson'*/}
                        {/*                },*/}
                        {/*                createdAt: 1554930000000,*/}
                        {/*                status: 'refunded'*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: '1f4e1bd0a87cea23cdb83d18',*/}
                        {/*                ref: 'DEV1046',*/}
                        {/*                amount: 96.43,*/}
                        {/*                customer: {*/}
                        {/*                    name: 'Anje Keizer'*/}
                        {/*                },*/}
                        {/*                createdAt: 1554757200000,*/}
                        {/*                status: 'pending'*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: '9f974f239d29ede969367103',*/}
                        {/*                ref: 'DEV1045',*/}
                        {/*                amount: 32.54,*/}
                        {/*                customer: {*/}
                        {/*                    name: 'Clarke Gillebert'*/}
                        {/*                },*/}
                        {/*                createdAt: 1554670800000,*/}
                        {/*                status: 'delivered'*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                id: 'ffc83c1560ec2f66a1c05596',*/}
                        {/*                ref: 'DEV1044',*/}
                        {/*                amount: 16.76,*/}
                        {/*                customer: {*/}
                        {/*                    name: 'Adam Denisov'*/}
                        {/*                },*/}
                        {/*                createdAt: 1554670800000,*/}
                        {/*                status: 'delivered'*/}
                        {/*            }*/}
                        {/*        ]}*/}
                        {/*        sx={{ height: '100%' }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                    </Grid>
    )
}