import axios from "axios";
import {API} from "../../config/api";

export default class ApiSendEmailBu {

    static async postZipFileEmail(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'uploadFileZip',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                        'Connection': 'upgrade',
                        'Upgrade': 'h2c'
                    }}
            );
        } catch (e) {
            return e;
        }
    }

    static async postZipFileEmailVmp(data) {
        try {
            return await axios.post(
                new API().getLoginAPI() +'api/UploadFileZipOnGoogle',
                data,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data'
                    }}
            );
        } catch (e) {
            return e;
        }
    }

}