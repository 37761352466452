import React, {Component, useState, useEffect, useRef, useContext} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArticleIcon from '@mui/icons-material/Article';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AssessmentIcon from '@mui/icons-material/Assessment';

import {AuthContext} from '../../App.js';
import CircularProgress from "@mui/material/CircularProgress";
const styles = {
    black: {},
    subIcon: {
        color: '#FEFEFE',
    },
    list: {
        backgroundColor: '#4380E1',
        borderRadius: 11,
        color: '#FEFEFE',
        // width:'400px'

    },
    icon: {
        color: '#4380E1',
    },




    mainOn:{
        backgroundColor: '#6F9EE8',
        borderRadius: 5,
    },
    mainOff:{
        backgroundColor: '#FEFEFE',
        borderRadius: 5,
    },
    iconOn: {
        color: '#FEFEFE',
    },
    iconOff: {
        color: '#4380E1',
    },
    typographyOn: {
        color: 'white',
    },
    typographyOff: {
        color: 'black',
    },
    subOn:{
        backgroundColor: '#6F9EE8',
        borderRadius: 5,
    },
    subOff:{
        backgroundColor: '#FEFEFE',
        borderRadius: 5,
    },

};

export default function MainListItems() {
    const {open, setOpen, userNPass,setUserNPass, profiles, setProfiles, isLoading, setIsLoading, isProfiles, setIsProfiles} = useContext(AuthContext);

    const [finance, setFinance] = useState(
        window.location.pathname === '/DowloadReportsDailyAnl' ||
        window.location.pathname === '/ReportsDailyAnl' ||
        window.location.pathname === '/finance/SendEmailInvoice' ||
        window.location.pathname === '/finance/TransportationCPF'
    );

    const [makro, setMakro] = useState(
        window.location.pathname === '/DowloadReportsDailyAnl'
        || window.location.pathname === '/ReportsDailyAnl'
    );
    const [master, setMaster] = useState(window.location.pathname.includes('/master'));
    const [shipment, setShipment] = useState(window.location.pathname.includes('shipment'));
    const [reportList, setReportList] = useState(window.location.pathname.includes('/report'));

    const handleListItemClick = (link, event) => {
        event.preventDefault()
        const urlWithoutParams = window.location.origin + link;
        if (event.ctrlKey || event.type === 'contextmenu' || event.button === 2) window.open(urlWithoutParams, '_blank', 'noopener,noreferrer'); // Open the URL in a new tab
        else window.location.href = urlWithoutParams; // Navigate to the URL without query parameters
    }

    return(
        <React.Fragment>
            <List component="nav" sx={{backgroundColor: '#ffffff', height: '100%', p: 0}}>
                <a href={'/'} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: '#000000'}}>
                    <Tooltip title="หน้าหลัก" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                        <ListItemButton sx={{pr: '8px'}} style={window.location.pathname === '/' ? styles.mainOn : styles.mainOff} onClick={(event) => handleListItemClick('/', event)}>
                            <ListItemIcon>
                                <HomeIcon style={window.location.pathname === '/' ? styles.iconOn : styles.iconOff}/>
                                {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/' ? styles.typographyOn : styles.typographyOff}>หน้าหลัก</Typography> : <></>}
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </a>

                <>
                    <Tooltip title="รายงาน" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                        <ListItemButton
                            sx={{pr: '8px', py: '16px'}}
                            onClick={() => {
                                setReportList(!reportList)
                            }}
                            style={styles.mainOff}
                        >
                            <ListItemIcon>
                                <AssessmentIcon style={styles.iconOff}/>
                                { open === true ?
                                    <Typography sx={{pl: '5px'}} style={styles.typographyOff}>รายงาน</Typography> : <></>
                                }
                            </ListItemIcon>
                            <Grid justifyContent="flex-end" container> {reportList === false ?
                                <ExpandMore sx={{color: '#4380E1'}}/> : <ExpandLess sx={{color: '#4380E1'}}/>}
                            </Grid>
                        </ListItemButton>
                    </Tooltip>
                    <Collapse in={reportList} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                profiles.data?.reportList?.map(m =>
                                    <a href={'/report/power_bi/' + m.id} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: '#000000'}}>
                                        <Tooltip title="Report List" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                            <ListItemButton
                                                sx={{pr: '8px', m:!open ? '0px' : '5px'}}
                                                style={window.location.pathname === ('/report/power_bi/' + m.id) ? styles.mainOn : styles.mainOff}
                                                onClick={(event) =>
                                                    handleListItemClick(('/report/power_bi/' + m.id + '?reportId=' + m.reportId + "&groupId=" + m.groupId), event)
                                                }>
                                                <ListItemIcon>
                                                    {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === ('/report/power_bi/' + m.id) ? styles.typographyOn : styles.typographyOff}>{m.reportName}</Typography> : <></>}
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Tooltip>
                                    </a>
                                )
                            }
                        </List>
                        <Divider/>
                    </Collapse>
                </>

                {
                    profiles.roles[0] === 'ROLE_SUPER_ADMIN' && (
                        <>
                            <Tooltip title="Finance" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                <ListItemButton sx={{pr: '8px',py: '16px'}} onClick={() => {setFinance(!finance)}} style={styles.mainOff}>
                                    <ListItemIcon>
                                        <AccountBalanceWalletIcon style={styles.iconOff}/>
                                        {open === true ? <Typography sx={{pl: '5px'}} style={styles.typographyOff}>Finance</Typography> : <></>}
                                    </ListItemIcon>
                                    <Grid justifyContent="flex-end" container> {finance === false ? <ExpandMore sx={{color: '#4380E1'}}/> : <ExpandLess sx={{color: '#4380E1'}}/>} </Grid>
                                </ListItemButton>
                            </Tooltip>

                            <Collapse in={finance} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding style={{paddingLeft: open ? '10px' : '0px'}}>
                                    <a href={'/finance/TransportationCPF'} target="_blank" rel="noreferrer"
                                       style={{textDecoration: "none", color: '#000000'}}>
                                        <Tooltip title="ส่ง Invoice" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                            <ListItemButton
                                                sx={{ pr: '8px' }}
                                                style={ window.location.pathname === '/finance/TransportationCPF' ? styles.mainOn : styles.mainOff }
                                                onClick={(event) => handleListItemClick('/finance/TransportationCPF', event)}>
                                                <ListItemIcon>
                                                    <RvHookupIcon style={window.location.pathname === '/finance/TransportationCPF' ? styles.iconOn : styles.iconOff}/>
                                                    {
                                                        open === true ?
                                                            <Typography sx={{pl: '5px'}} style={window.location.pathname === '/finance/TransportationCPF' ? styles.typographyOn : styles.typographyOff}>
                                                                เที่ยววิ่ง CPF
                                                            </Typography> : <></>
                                                    }
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Tooltip>
                                    </a>

                                    <a href={'/finance/SendEmailInvoice'} target="_blank" rel="noreferrer"
                                       style={{textDecoration: "none", color: '#000000'}}>
                                        <Tooltip title="ส่ง Invoice" TransitionComponent={Zoom} placement="right" arrow
                                                 disableHoverListener={open}>
                                            <ListItemButton sx={{pr: '8px'}}
                                                            style={window.location.pathname === '/finance/SendEmailInvoice' ? styles.mainOn : styles.mainOff}
                                                            onClick={(event) => handleListItemClick('/finance/SendEmailInvoice', event)}>
                                                <ListItemIcon>
                                                    <EmailIcon
                                                        style={window.location.pathname === '/finance/SendEmailInvoice' ? styles.iconOn : styles.iconOff}/>
                                                    {open === true ? <Typography sx={{pl: '5px'}}
                                                                                 style={window.location.pathname === '/finance/SendEmailInvoice' ? styles.typographyOn : styles.typographyOff}>ส่ง
                                                        Invoice</Typography> : <></>}
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Tooltip>
                                    </a>

                                    <Tooltip title="Makro" TransitionComponent={Zoom} placement="right" arrow
                                             disableHoverListener={open}>
                                        <ListItemButton sx={{pr: '8px', py: '16px'}} onClick={() => {
                                            setMakro(!makro)
                                        }} style={styles.mainOff}>
                                            <ListItemIcon>
                                                <InventoryIcon style={styles.iconOff}/>
                                                {open === true ? <Typography sx={{pl: '5px'}}
                                                                             style={styles.typographyOff}>Makro</Typography> : <></>}
                                            </ListItemIcon>
                                            <Grid justifyContent="flex-end" container> {makro === false ?
                                                <ExpandMore sx={{color: '#4380E1'}}/> :
                                                <ExpandLess sx={{color: '#4380E1'}}/>} </Grid>
                                        </ListItemButton>
                                    </Tooltip>
                                    <Collapse in={makro} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <a href={'/ReportsDailyAnl'} target="_blank" rel="noreferrer"
                                               style={{textDecoration: "none", color: '#000000'}}>
                                                <Tooltip title="อัพโหลดไฟล์" TransitionComponent={Zoom}
                                                         placement="right" arrow
                                                         disableHoverListener={open}>
                                                    <ListItemButton sx={{pr: '8px', m: !open ? '0px' : '5px'}}
                                                                    style={window.location.pathname === '/ReportsDailyAnl' ? styles.mainOn : styles.mainOff}
                                                                    onClick={(event) => handleListItemClick('/ReportsDailyAnl', event)}>
                                                        <ListItemIcon>
                                                            {/*<UploadFileIcon style={window.location.pathname === '/ReportsDailyAnl' ? styles.iconOn : styles.iconOff}/>*/}
                                                            {open === true ? <Typography sx={{pl: '5px'}}
                                                                                         style={window.location.pathname === '/ReportsDailyAnl' ? styles.typographyOn : styles.typographyOff}>นำเข้าข้อมูล
                                                                Daily</Typography> : <MoreHorizOutlinedIcon
                                                                style={window.location.pathname === '/ReportsDailyAnl' ? styles.iconOn : styles.iconOff}/>}
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                </Tooltip>
                                            </a>
                                            <a href={'/DowloadReportsDailyAnl'} target="_blank" rel="noreferrer"
                                               style={{textDecoration: "none", color: '#000000'}}>
                                                <Tooltip title="ดาวโหลดไฟล์" TransitionComponent={Zoom}
                                                         placement="right" arrow
                                                         disableHoverListener={open}>
                                                    <ListItemButton sx={{pr: '8px', m: !open ? '0px' : '5px'}}
                                                                    style={window.location.pathname === '/DowloadReportsDailyAnl' ? styles.mainOn : styles.mainOff}
                                                                    onClick={(event) => handleListItemClick('/DowloadReportsDailyAnl', event)}>
                                                        <ListItemIcon>
                                                            {/*<ArticleIcon  style={window.location.pathname === '/DowloadReportsDailyAnl' ? styles.iconOn : styles.iconOff}/>*/}
                                                            {open === true ? <Typography sx={{pl: '5px'}}
                                                                                         style={window.location.pathname === '/DowloadReportsDailyAnl' ? styles.typographyOn : styles.typographyOff}>ออกรายงาน</Typography> :
                                                                <MoreHorizOutlinedIcon
                                                                    style={window.location.pathname === '/DowloadReportsDailyAnl' ? styles.iconOn : styles.iconOff}/>}
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                </Tooltip>
                                            </a>
                                        </List>
                                    </Collapse>
                                </List>
                                <Divider/>
                            </Collapse>
                        </>
                    )
                }

                <Tooltip title="ข้อมูลตั้งต้น" TransitionComponent={Zoom} placement="right" arrow
                         disableHoverListener={open}>
                    <ListItemButton sx={{pr: '8px', py: '16px'}} onClick={() => {
                        setMaster(!master)
                    }} style={styles.mainOff}>
                        <ListItemIcon>
                            <AssignmentIcon style={styles.iconOff}/>
                            {open === true ? <Typography sx={{pl: '5px'}}
                                                         style={styles.typographyOff}>ข้อมูลตั้งต้น</Typography> : <></>}
                        </ListItemIcon>
                        <Grid justifyContent="flex-end" container> {master === false ?
                            <ExpandMore sx={{color: '#4380E1'}}/> : <ExpandLess sx={{color: '#4380E1'}}/>} </Grid>
                    </ListItemButton>
                </Tooltip>
                <Collapse in={master} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            profiles.roles[0] === 'ROLE_SUPER_ADMIN' && (
                                <a href={'/master/customer'} target="_blank" rel="noreferrer"
                                   style={{textDecoration: "none", color: '#000000'}}>
                                    <Tooltip title="Customer" TransitionComponent={Zoom} placement="right" arrow
                                             disableHoverListener={open}>
                                    <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/master/customer' ? styles.mainOn : styles.mainOff} onClick={(event) => handleListItemClick('/master/customer', event)}>
                                            <ListItemIcon>
                                                {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/master/customer' ? styles.typographyOn : styles.typographyOff}>Customer</Typography> : <MoreHorizOutlinedIcon style={window.location.pathname === '/master/customer' ? styles.iconOn : styles.iconOff}/>}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </Tooltip>
                                </a>
                            )
                        }

                        {
                            profiles.bu.some(item => item?.includes('CPALL')) && (
                                <a href={'/master/cpallWindowTime'} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: '#000000'}}>
                                    <Tooltip title="Window time cpall" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                        <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/master/cpallWindowTime' ? styles.mainOn : styles.mainOff} onClick={(event) => handleListItemClick('/master/cpallWindowTime', event)}>
                                            <ListItemIcon>
                                                {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/master/cpallWindowTime' ? styles.typographyOn : styles.typographyOff}>Window time cpall</Typography> : <MoreHorizOutlinedIcon style={window.location.pathname === '/master/cpallWindowTime' ? styles.iconOn : styles.iconOff}/>}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </Tooltip>
                                </a>
                            )
                        }

                        {
                            profiles.roles[0] === 'ROLE_SUPER_ADMIN' && (
                                <a href={'/master/Subcontractors'} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: '#000000'}}>
                                    <Tooltip title="Subcontractors" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                        <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/master/Subcontractors' ? styles.mainOn : styles.mainOff} onClick={(event) => handleListItemClick('/master/Subcontractors', event)}>
                                            <ListItemIcon>
                                                {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/master/Subcontractors' ? styles.typographyOn : styles.typographyOff}>Subcontractors</Typography> : <MoreHorizOutlinedIcon style={window.location.pathname === '/master/Subcontractors' ? styles.iconOn : styles.iconOff}/>}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </Tooltip>
                                </a>
                            )
                        }
                    </List>
                    <Divider/>
                </Collapse>
                {/*{
                    profiles.roles[0] === 'ROLE_SUPER_ADMIN' && (
                        <>
                        <Tooltip title="ข้อมูลตั้งต้น" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                            <ListItemButton sx={{pr: '8px',py: '16px'}} onClick={() => {setMaster(!master)}} style={styles.mainOff}>
                                <ListItemIcon>
                                    <AssignmentIcon style={styles.iconOff}/>
                                    {open === true ? <Typography sx={{pl: '5px'}} style={styles.typographyOff}>ข้อมูลตั้งต้น</Typography> : <></>}
                                </ListItemIcon>
                                <Grid justifyContent="flex-end" container> {master === false ? <ExpandMore sx={{color: '#4380E1'}}/> : <ExpandLess sx={{color: '#4380E1'}}/>} </Grid>
                            </ListItemButton>
                        </Tooltip>
                        <Collapse in={master} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <a href={'/master/customer'} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: '#000000'}}>
                                    <Tooltip title="Customer" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                        <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/master/customer' ? styles.mainOn : styles.mainOff} onClick={(event) => handleListItemClick('/master/customer', event)}>
                                            <ListItemIcon>
                                                 {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/master/customer' ? styles.typographyOn : styles.typographyOff}>Customer</Typography> : <MoreHorizOutlinedIcon style={window.location.pathname === '/master/customer' ? styles.iconOn : styles.iconOff}/>}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </Tooltip>
                                </a>

                            </List>
                            <Divider/>
                        </Collapse>
                        </>
                    )
                }*/}
                <Tooltip title="shipment" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                    <ListItemButton sx={{pr: '8px',py: '16px'}} onClick={() => {setShipment(!shipment)}} style={styles.mainOff}>
                        <ListItemIcon>
                            <LocalShippingIcon style={styles.iconOff}/>
                            {open === true ? <Typography sx={{pl: '5px'}} style={styles.typographyOff}>Shipment</Typography> : <></>}
                        </ListItemIcon>
                        <Grid justifyContent="flex-end" container> {shipment === false ? <ExpandMore sx={{color: '#4380E1'}}/> : <ExpandLess sx={{color: '#4380E1'}}/>} </Grid>
                    </ListItemButton>
                </Tooltip>
                <Collapse in={shipment} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <a href={'/shipment/importFile'} target="_blank" rel="noreferrer" style={{textDecoration: "none", color: '#000000'}}>
                            <Tooltip title="Import file" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/shipment/importFile' ? styles.subOn : styles.subOff} onClick={(event) => handleListItemClick('/shipment/importFile', event)}>
                                    <ListItemIcon>
                                        {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/shipment/importFile' ? styles.typographyOn : styles.typographyOff}>Import file</Typography>  : <MoreHorizOutlinedIcon style={window.location.pathname === '/shipment/importFile' ? styles.iconOn : styles.iconOff}/>}
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                        </a>
                        <a href={'/shipment/orderManagement'} target="_blank" rel="noreferrer" style={{textDecoration: "none", color: '#000000'}}>
                            <Tooltip title="Order management" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname.includes('/shipment/orderManagement') ? styles.subOn : styles.subOff} onClick={(event) => handleListItemClick('/shipment/orderManagement', event)}>
                                    <ListItemIcon>
                                        {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname.includes('/shipment/orderManagement')  ? styles.typographyOn : styles.typographyOff}>Order management</Typography>  : <MoreHorizOutlinedIcon style={window.location.pathname.includes('/shipment/orderManagement')  ? styles.iconOn : styles.iconOff}/>}
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                        </a>

                        {/*<a href={'/shipment/logCreateOrderTMS'} target="_blank" rel="noreferrer" style={{textDecoration: "none", color: '#000000'}}>*/}
                        {/*    <Tooltip title="TMS's order" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>*/}
                        {/*        <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/shipment/logCreateOrderTMS' ? styles.subOn : styles.subOff} onClick={(event) => handleListItemClick('/shipment/logCreateOrderTMS', event)}>*/}
                        {/*            <ListItemIcon>*/}
                        {/*                {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/shipment/logCreateOrderTMS' ? styles.typographyOn : styles.typographyOff}>TMS's order (All now)</Typography>  : <MoreHorizOutlinedIcon style={window.location.pathname === '/shipment/logCreateOrderTMS' ? styles.iconOn : styles.iconOff}/>}*/}
                        {/*            </ListItemIcon>*/}
                        {/*        </ListItemButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</a>*/}

                        <a href={'/shipment/AllocationReport'} target="_blank" rel="noreferrer" style={{textDecoration: "none", color: '#000000'}}>
                            <Tooltip title="Allocation Report" TransitionComponent={Zoom} placement="right" arrow disableHoverListener={open}>
                                <ListItemButton sx={{pr: '8px', m:!open ? '0px' : '5px'}} style={window.location.pathname === '/shipment/AllocationReport' ? styles.subOn : styles.subOff} onClick={(event) => handleListItemClick('/shipment/AllocationReport', event)}>
                                    <ListItemIcon>
                                        {open === true ? <Typography sx={{pl: '5px'}} style={window.location.pathname === '/shipment/AllocationReport' ? styles.typographyOn : styles.typographyOff}>Allocation report</Typography>  : <MoreHorizOutlinedIcon style={window.location.pathname === '/shipment/AllocationReport' ? styles.iconOn : styles.iconOff}/>}
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                        </a>

                    </List>
                    <Divider/>
                </Collapse>
            </List>
        </React.Fragment>
    )
}