import React, {useEffect, useState, useContext} from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {DataGrid, GridToolbar, useGridApiContext, GridCellEditStopReasons, GridEditInputCell} from '@mui/x-data-grid';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Divider from "@mui/material/Divider";
import {AuthContext} from "../../App";
import {darken, lighten, styled} from '@mui/material/styles';
import Loading from "../Loading";
import ApiWindowTime from "../../services/api/ApiWindowTime";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Tooltip from '@mui/material/Tooltip';
import FileDownload from "js-file-download";
import {API} from "../../config/api";
import CustomNoRowsOverlay from "../../components/EmptyRowsComponent";


const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .red': {
        backgroundColor: '#f6d3d3',
        '&:hover': {
            backgroundColor: '#f6c0c0',
        },
        '&.Mui-selected': {
            backgroundColor: '#EDF4FB',
            '&:hover': {
                backgroundColor: '#e0e9f5',
            },
        },
    },
    '& .yellow': {
        backgroundColor: '#f3efcc',
        '&:hover': {
            backgroundColor: '#f5f0b7',
        },
        '&.Mui-selected': {
            backgroundColor: '#EDF4FB',
            '&:hover': {
                backgroundColor: '#e0e9f5',
            },
        },
    },
}));

export default function WindowTimeCpall() {
    const pathApi = new API().getCusAPI();
    const {
        open,
        setOpen,
        userNPass,
        setUserNPass,
        isLoading,
        setIsLoading,
        isProfiles,
        setIsProfiles,
        isProgress,
        setIsProgress,
        profiles,
        testMode,
    } = useContext(AuthContext);

    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [drawer, setDrawer] = useState('block');

    const [masterPickup, setMasterPickup] = useState([]);
    const [masterDelivery, setMasterDelivery] = useState([]);
    const [masterDialog, setMasterDialog] = useState({
        open: false,
        file: [],
    });

    useEffect(() => {
        async function inti() {
            await fetchData('Y').catch((e)=>{
                console.log(e)
                setIsLoading(false)
                setIsProgress(false)
            })
        }
        inti().catch(console.error)
    }, []);
    // window.confirm("คุณต้องการลบรายการนี้หรือไม่?")
    const fetchData = async () => {
        setIsLoading(true)
        setIsProgress(true)
        // console.log(profiles.bu)
        const filteredCpAll = profiles.bu?.filter(item => item?.includes('CPALL'));
        // console.log(filteredCpAll);
        let res = await ApiWindowTime.get({customerName:filteredCpAll});
        if (res.status === 200) {
            setMasterDelivery(res.data.delivery)
            setMasterPickup(res.data.pickup)
            rows = masterPickup.map((item, index) => ({
                id: item.id,
                customer: item.buName,
                dc: item.dc,
                line: item.line,
                time: item.time,
                pickupNumDay: item.pickupNumDay,
                deliveryNumDay: item.deliveryNumDay,
            }));

            rowsDelivery = masterDelivery.map((item, index) => ({
                id: item.id,
                customer: item.buName,
                dc: item.name,
                time: item.time,
            }));
        }
        setIsLoading(false)
        setIsProgress(false)
    }
    const columnsDelivery = [
        {
            field: "customer",
            headerName: "Customer",
            flex: 2,
            minWidth: 100,
        },
        {
            field: "dc",
            headerName: "DC",
            flex: 2,
            minWidth: 100,
            sortable: true ,
        },
        {
            field: "time",
            headerName: "เวลาส่งสินค้าช้าสุด",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
        },
        {
            field: "day",
            headerName: " + วันส่งสินค้า",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
            renderCell: (params) => {
                return <span> { params.row.day ? params.row.day : '' } </span>
            }
        }
    ];

    let columns = [
        {
            field: "customer",
            headerName: "Customer",
            flex: 2,
            minWidth: 100,
        },
        {
            field: "dc",
            headerName: "DC",
            flex: 1,
            minWidth: 100,
            sortable: true,
        },
        {
            field: "line",
            headerName: "สายงาน",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
        },
        {
            field: "pickupTime",
            headerName: "เวลารับสินค้า",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
        },
        {
            field: "pickupNumDay",
            headerName: " + วันรับสินค้า",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
        },
        {
            field: "deliveryTime",
            headerName: "เวลาส่งสินค้า",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
        },
        {
            field: "deliveryNumDay",
            headerName: " + วันส่งสินค้า",
            flex: 1,
            minWidth: 80,
            sortable: true,
            editable: true,
        },
    ];
    let rows = masterPickup.map((item, index) => ({
        id: item.id,
        customer: item.buName,
        dc: item.dc,
        line: item.line,
        pickupTime: item.pickupTime,
        deliveryTime: item.deliveryTime,
        pickupNumDay: item.pickupNumDay,
        deliveryNumDay: item.deliveryNumDay,
    }));

    let rowsDelivery = masterDelivery.map((item, index) => ({
        id: item.id,
        customer: item.buName,
        dc: item.name,
        time: item.time,
        day: item.day,
    }));

    // if (profiles.bu[0] === 'CPALL เชียงใหม่') columns = [...columns.slice(0, 3), ...columns.slice(4)];

    const processRowUpdateDelivery = React.useCallback(
        async (newRow) => {
            // console.log(newRow)
            const res = await ApiWindowTime.updateDelivery({
                userId: JSON.parse(localStorage.getItem("profile")).userId,
                id: newRow.id,
                time: newRow.time,
                day: newRow.day
            });
            if (res.status === 200) {
                await fetchData().catch((e) => {
                    console.log(e);
                    setIsProgress(false);
                });
                return newRow
            } else {
                alert('ไม่สำเสร็จ')
                return false;
            }
        }, [fetchData],
    );

    const processRowUpdatePickup= React.useCallback(
        async (newRow) => {
            // console.log(newRow)
            const res = await ApiWindowTime.updatePickupCm({
                userId: JSON.parse(localStorage.getItem("profile")).userId,
                role: JSON.parse(localStorage.getItem("profile")).userRoles[0],
                id: newRow.id,
                dc: newRow.dc,
                line: newRow.line,
                pickupTime: newRow.pickupTime,
                deliveryTime: newRow.deliveryTime,
                pickupNumDay: newRow.pickupNumDay,
                deliveryNumDay: newRow.deliveryNumDay
            });
            if (res.status === 200) {
                await fetchData().catch((e) => {
                    console.log(e);
                    setIsProgress(false);
                });
                return newRow
            } else {
                alert('ไม่สำเสร็จ')
                return false;
            }
        }, [fetchData],
    );

    const openDialog = () => {
        setMasterDialog({
            open: true,
            file: [],
        })
    }
    
    const resetDialog = () => {
        setMasterDialog({
            open: false,
            file: [],
        })
    }

    const DrawerBtn = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (drawerOpenBtn === false) setDrawer('block')
        if (drawerOpenBtn === true) setDrawer('none')
    };

    const textDefaultDelivery = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (profiles.roles[0] !== 'ROLE_SUPER_ADMIN') {
            if (profiles.bu[0] === 'CPALL เชียงใหม่') return 'ถ้าไม่มีข้อมูลจะตั้งค่าเริ่มต้นเป็น { profiles.bu[0] } 10:00'
            else if (profiles.bu[0] === 'CPALL นครราชสีมา') return 'ถ้าไม่มีข้อมูลจะตั้งค่าเริ่มต้นเป็น { profiles.bu[0] } 10:00'
        } else return 'ถ้าไม่มีข้อมูลจะตั้งค่าเริ่มต้นเป็น '
    };

    const filteredCpAllBuName = profiles.bu?.filter(item => item?.includes('CPALL'));

    return (
        <>
            {
                !isProfiles
                    ? <Loading/>
                    :
                    <>
                        <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, pb: '30px'}}>
                            <Grid container>
                                <Grid xs={12} item direction="row" container justifyContent="space-between">
                                    <Typography variant="h5" sx={{pl: '0px', pb: '15px', fontSize: '22px'}}>
                                        Window Time
                                    </Typography>
                                    <Tooltip title="รีเซตข้อมูล" placement="left">
                                        <IconButton onClick={()=>(fetchData())}>
                                            <CachedOutlinedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Divider sx={{mb: '10px'}}/>
                            <Grid container sx={{pb:'20px'}}>
                                {
                                    profiles.bu[0] !== 'CPALL นครราชสีมา'
                                    ?
                                        <Grid xs={12} item>
                                            <Stack>
                                                <Typography variant="h4" sx={{ml: {xs: 0, md: 1}, mb: '5px', mt:'10px', fontSize: '18px'}}>
                                                    Delivery time
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{pl: '10px', pb: '15px', fontSize: '16px'}}>
                                                    ถ้าไม่มีข้อมูลจะตั้งค่าเริ่มต้นเวลาส่งสินค้าเป็น 10:00 (เชียงใหม่จะใช้ delivery time ตาม DC) +1 วัน
                                                </Typography>
                                            </Stack>
                                            <StyledDataGrid
                                                getRowHeight={() => 45}
                                                sx={{
                                                    mt: '15px',
                                                    mx: '5px',
                                                    flex: 7,
                                                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                                        outline: 'none',
                                                    },
                                                    '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                                        whiteSpace: "normal !important",
                                                        wordWrap: "break-word !important",
                                                        textAlign: 'center',
                                                    },
                                                }}
                                                density="compact"
                                                loading={isProgress}
                                                autoHeight
                                                rows={rowsDelivery}
                                                columns={columnsDelivery}
                                                disableDensitySelector
                                                disableColumnSelector
                                                initialState={{
                                                    pagination: { paginationModel: {page: 0, pageSize: 10}, },
                                                    columns: { columnVisibilityModel: {}, },
                                                }}
                                                pageSizeOptions={[10, 50, 100, {label: 'ทั้งหมด', value: -1}]}
                                                slots={{
                                                    toolbar: GridToolbar,
                                                    loadingOverlay: LinearProgress
                                                }}
                                                componentsProps={{
                                                    toolbar: {
                                                        csvOptions: {
                                                            fileName: 'delivery_time',
                                                            utf8WithBom: true,
                                                        },
                                                        printOptions: {disableToolbarButton: true},
                                                    },
                                                }}
                                                processRowUpdate={processRowUpdateDelivery}
                                            />
                                        </Grid>
                                    :
                                        <></>
                                }

                            </Grid>
                            <Grid container>
                                <Grid xs={12} item>
                                    <Grid xs={12} item direction="row" container justifyContent="space-between">
                                        <Stack>
                                            <Typography variant="h4" sx={{ml: {xs: 0, md: 1}, mb: '0px', mt:'10px', fontSize: '18px'}}>
                                                Pickup time {profiles.bu[0] === 'CPALL นครราชสีมา' ? ' & Delivery time' : ''}
                                                <Tooltip title="ดาวน์โหลดตัวอย่างไฟล์ Import" placement="right">
                                                    <IconButton
                                                        onClick={ async ()=>{
                                                            let res = await ApiWindowTime.downloadTemplateImport();
                                                            if (res.status !== 200){
                                                                setIsLoading(false)
                                                                Swal.fire({
                                                                    title: 'ดาวน์โหลดไม่สำเร็จ',
                                                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                    icon: 'error',
                                                                    showConfirmButton: false,
                                                                })
                                                            } else FileDownload(res.data, 'Pick_up_time_CPALL.xlsx');
                                                        }}
                                                    >
                                                        <InsertDriveFileOutlinedIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                {profiles.bu[0] === 'CPALL เชียงใหม่' ? ' <- ต้องใช้ไฟล์นี้ Import Window time เท่านั้น !' : ''}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{pl: '10px', pb: '15px', fontSize: '16px'}}>
                                                ถ้าไม่มีข้อมูลจะตั้งค่าเริ่มต้นเวลารับสินค้าเป็น 06:00 {profiles.bu[0] === 'CPALL นครราชสีมา' ? ' และเวลาส่งสินค้าเป็น 23:00 ภายในวันเดียวกัน' : ''}
                                            </Typography>
                                        </Stack>
                                        <Button variant="outlined" size="small" onClick={()=>(openDialog())} sx={{mx: {xs: 0, md: 1}, mb: '15px', mt:'60px', height: '30px'}}>
                                            Import
                                        </Button>
                                    </Grid>
                                    <StyledDataGrid
                                        sx={{
                                            mt: '15px',
                                            mx: '5px',
                                            flex: 20,
                                            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                                whiteSpace: "normal !important",
                                                wordWrap: "break-word !important",
                                                textAlign: 'center',
                                            },
                                        }}
                                        density="compact"
                                        loading={isProgress}
                                        autoHeight
                                        rows={rows}
                                        columns={columns}
                                        getRowHeight={() => 45}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {page: 0, pageSize: 10},
                                            },
                                        }}
                                        pageSizeOptions={[10, 50, 100, {label: 'ทั้งหมด', value: -1}]}
                                        slots={{
                                            toolbar: GridToolbar,
                                            loadingOverlay: LinearProgress
                                        }}
                                        disableDensitySelector
                                        disableColumnSelector
                                        componentsProps={{
                                            toolbar: {
                                                csvOptions: {
                                                    fileName: 'pickup_time',
                                                    utf8WithBom: true,
                                                },
                                                printOptions: {disableToolbarButton: true},
                                            },
                                        }}
                                        processRowUpdate={processRowUpdatePickup}
                                    />
                                </Grid>
                            </Grid>
                            <Dialog open={masterDialog.open} keepMounted onClose={()=>(resetDialog())} aria-describedby="alert-dialog-slide-description" maxWidth="sm" fullWidth sx={{zIndex: 1050}}>
                                <DialogTitle>Import Pickup Time</DialogTitle>
                                <DialogContent dividers sx={{}}>
                                    {isProgress &&(<LinearProgress/>)}
                                    <Grid container sx={{pt: 2,pl:{xs:0,md:1}}} direction="column" justifyContent="center" alignItems="start" columns>
                                        <Grid container sx={{pl:'20px'}} direction="row" alignItems="start" justifyContent="start" item>
                                            <input
                                                type={"file"}
                                                accept={".xlsx"}
                                                onChange={(e)=>{
                                                    if (e.target.files[0]) setMasterDialog(masterDialog=>({...masterDialog,...{file: e.target.files[0]}}))
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={12} item sx={{pt: 1}} container direction="row" justifyContent="flex-end" alignItems="end">
                                            <Button
                                                disabled={ masterDialog.file.length <= 0 ? true : isProgress}
                                                variant="contained"
                                                disableElevation
                                                onClick={ async ()=>{
                                                    setIsProgress(true)
                                                    let importFile = await ApiWindowTime.import({
                                                        file: masterDialog.file,
                                                        userId: JSON.parse(localStorage.getItem("profile")).userId,
                                                        role: JSON.parse(localStorage.getItem("profile")).userRoles[0],
                                                        customerName:profiles.roles[0] !== 'ROLE_SUPER_ADMIN' ? profiles.bu[0]: null,
                                                    })
                                                    if (importFile.status === 200) {
                                                        Swal.fire({
                                                            title: 'อัพโหลดสำเร็จ',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                        }).then( async () => {
                                                            resetDialog()
                                                            await fetchData().catch((e)=>{
                                                                console.log(e)
                                                                setIsProgress(false)
                                                            })
                                                        })
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: '#6F9EE8',
                                                    color: '#ffffff',
                                                    width:'100px',
                                                    ':hover': {
                                                        backgroundColor: '#4380E1',
                                                        color: 'white',
                                                    },
                                                }}
                                            >
                                                Import
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </>
            }
        </>
    );
}
