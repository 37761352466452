export class API {
    env = process.env.REACT_APP_ENV

    PROD_URL_API = process.env.REACT_APP_PROD_URL_API
    UAT_URL_API = process.env.REACT_APP_UAT_URL_API
    DEV_URL_API = process.env.REACT_APP_DEV_URL_API

    PROD_URL_ALLOCATE = process.env.REACT_APP_PROD_URL_ALLOCATE
    UAT_URL_ALLOCATE = process.env.REACT_APP_UAT_URL_ALLOCATE
    DEV_URL_ALLOCATE = process.env.REACT_APP_DEV_URL_ALLOCATE

    PROD_URL_LOGIN = process.env.REACT_APP_PROD_URL_API_LOGIN
    UAT_URL_LOGIN = process.env.REACT_APP_UAT_URL_API_LOGIN
    DEV_URL_LOGIN = process.env.REACT_APP_DEV_URL_API_LOGIN

    PROD_URL_TMS = process.env.REACT_APP_PROD_URL_API_TMS
    UAT_URL_TMS = process.env.REACT_APP_UAT_URL_API_TMS
    DEV_URL_TMS = process.env.REACT_APP_DEV_URL_API_TMS

    PROD_URL_ZYNX = process.env.REACT_APP_PROD_URL_ZYNX
    UAT_URL_ZYNX = process.env.REACT_APP_UAT_URL_ZYNX
    DEV_URL_ZYNX = process.env.REACT_APP_UAT_URL_ZYNX

    getZynx() {
        switch (this.env) {
            case "PROD":
                return this.PROD_URL_ZYNX
            case "UAT":
                return this.UAT_URL_ZYNX
            default:
                return this.DEV_URL_ZYNX
        }
    }

    getCusAPI() {
        switch (this.env) {
            case "PROD":
                return this.PROD_URL_API
            case "UAT":
                return this.UAT_URL_API
            default:
                return this.DEV_URL_API
        }
    }

    getAllocateAPI() {
        switch (this.env) {
            case "PROD":
                return this.PROD_URL_ALLOCATE
            case "UAT":
                return this.UAT_URL_ALLOCATE
            default:
                return this.DEV_URL_ALLOCATE
        }
    }

    getLoginAPI() {
        switch (this.env) {
            case "PROD":
                return this.PROD_URL_LOGIN
            case "UAT":
                return this.UAT_URL_LOGIN
            default:
                return this.DEV_URL_LOGIN
        }
    }

    getTMSAPI() {
        switch (this.env) {
            case "PROD":
                return this.PROD_URL_TMS
            case "UAT":
                return this.UAT_URL_TMS
            default:
                return this.DEV_URL_TMS
        }
    }
}