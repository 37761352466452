import Iframe from 'react-iframe'
import {API} from "../config/api";

export default function Subcontractors(props) {
    return(
        <div
            style={{width: '100%', position: 'relative', }}
        >
            <Iframe
                url={new API().getCusAPI() + "admin?crudAction=index&crudControllerFqcn=App\\Controller\\Admin\\SubconstractorCrudController"}
                width="100%"
                id="myId"
                className="custom-iframe"
                height="85vh"
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </div>
    )
}